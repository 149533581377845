import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit"
import {
  camelCase,
  difference,
  flatMap,
  flow,
  isEqual,
  isUndefined,
  map,
  mapKeys,
  omitBy,
  uniq,
} from "lodash/fp"
import { createCachedSelector } from "re-reselect"
import { PLATFORM } from "../../features/home/constants/platforms"
import missingIcon from "../../shared/assets/missing-icon.png"
import {
  generateAllLaunchLinks,
  getCategoriesString,
} from "../../shared/utils/utils"
import { BASE_URL, api, authApi } from "../api"
import { fetchAllOwnedListsV2, fetchUserProfile } from "../lists/lists.slice"
import { selectAllManifests } from "../manifests/manifests.slice"
import { selectAllOverrides } from "../overrides/overrides.slice"
import { createRequestStatusSelector, isAppApproved, isPwa } from "../util"
import { forEach } from "lodash"

export const deleteApp = createAsyncThunk(
  `apps/delete`,
  async ({ api, appId }, { getState }) => {
    await api.delete(`${BASE_URL}/apps/${appId}`)

    const target = Object.values(getState().listings?.entities).find(
      (item) => item.app_id === appId,
    )

    return {
      id: target?.id,
      appId: appId,
    }
  },
  { idGenerator: () => deleteApp.typePrefix },
)

export const updateListing = createAsyncThunk(
  `listings/update`,
  async ({ api, id, publish, discoverable, tags, internalRanking }) => {
    return await api.patch(
      `${BASE_URL}/v2/listings/${id}`,
      omitBy(isUndefined)({
        publish,
        discoverable,
        tags,
        internal_ranking: internalRanking,
      }),
    )
  },
  { idGenerator: () => updateListing.typePrefix },
)

// Listings V2 - Base Actions
/**
 * Fetches 20 listings at a time. Repeatedly call this function to load more listings.
 * @param {string} category - The primary category to fetch (optional)
 * @param {string} subCategory - The sub category to fetch (optional)
 * @param {Array} apps - The app IDs to filter by (optional)
 * @param {Object} filters - The filters & sorts to apply (camelCased keys) (optional)
 */
export const fetchListingsV2 = createAsyncThunk(
  `listings/get`,
  async (
    { category, subCategory, apps = [], filters = {} } = {},
    { getState },
  ) => {
    const url = new URL(`${BASE_URL}/v2/listings`)
    const params = new URLSearchParams()
    const limit = 20

    const categories = getCategoriesString(category, subCategory)

    let page = getState().listings.s[categories]?.page || 0
    if (!isEqual(getState().listings.s[categories]?.filters || {})(filters)) {
      page = 0
    }
    if (page === -1) return Promise.resolve([])

    if (categories !== "featured") {
      const offset = page * limit
      params.append("offset", offset)
      params.append("limit", limit)
    }

    apps.forEach((appId) => {
      params.append("app", appId)
    })

    if (categories && categories !== "all") {
      params.append("category", categories)
    }

    const sessionSeed = getState().listings.s.seed
    if (sessionSeed) {
      params.append("seed", sessionSeed)
    }

    appendFilterParams({ params, ...filters })

    url.search = params.toString()

    return api.get(url.toString())
  },
  {
    // Enables segmenting request status per category
    idGenerator: ({ category, subCategory } = {}) => {
      const categories = getCategoriesString(category, subCategory)
      return categories || fetchListingsV2.typePrefix
    },
  },
)

export const fetchListingV2 = createAsyncThunk(
  `listings/fetchListingV2`,
  async (slug) => api.get(`${BASE_URL}/v2/listings/${slug}`),
  { idGenerator: () => fetchListingV2.typePrefix },
)

// Listing V2 - For You
export const fetchListingsForYouV2 = createAsyncThunk(
  `listings/for-you/get`,
  async (arg = "for_you") => authApi.get(`${BASE_URL}/v2/listings/for-you`),
  { idGenerator: () => fetchListingsForYouV2.typePrefix },
)

// Listing V2 - New
/**
 * Fetches the 100 most recent published & discoverable listings. Response format from API mimics fetchListingsV2.
 * @param {Object} filters - The filters & sorts to apply (camelCased keys) (optional)
 */
export const fetchListingsNew = createAsyncThunk(
  `listings/new/get`,
  async ({ filters = {} }) => {
    const url = new URL(`${BASE_URL}/v2/listings/new`)
    const params = new URLSearchParams()
    appendFilterParams({ params, ...filters })
    url.search = params.toString()
    return api.get(url.toString())
  },
  { idGenerator: () => "new" },
)

// Listing V2 - All Content
export const fetchAllContentV2 = createAsyncThunk(
  `listings/fetchAllContentV2`,
  async ({ api, sitePath, overrideType = "developer" }) =>
    api.get(
      `${BASE_URL}/listings/${sitePath}/all_content?override_type=${overrideType}`,
    ),
  { idGenerator: () => fetchAllContentV2.typePrefix },
)

// Listings V2 - Claim
export const createPendingClaimV2 = createAsyncThunk(
  `listings/claim/create`,
  async (url) => await authApi.post(`${BASE_URL}/v2/listings/claim`, { url }),
  { idGenerator: () => createPendingClaimV2.typePrefix },
)

export const getDomainVerifyStatus = createAsyncThunk(
  `claimedApps/verify/status`,
  async (appId) => await authApi.get(`${BASE_URL}/apps/${appId}/verify`),
  { idGenerator: () => getDomainVerifyStatus.typePrefix },
)

export const fetchClaimedListingsV2 = createAsyncThunk(
  `listings/claim/get`,
  async () => authApi.get(`${BASE_URL}/v2/listings/claim`),
  { idGenerator: () => fetchClaimedListingsV2.typePrefix },
)

// Listings V2 - Admin Only
export const fetchListingAdminV2 = createAsyncThunk(
  `listings/fetchListingAdminV2`,
  async ({ api, id, publish = "*" }) =>
    api.get(`${BASE_URL}/admin/v2/listings/${id}?publish=${publish}`),
  { idGenerator: () => fetchListingAdminV2.typePrefix },
)

export const fetchListingsAdminV2 = createAsyncThunk(
  `listings/get/admin`,
  async ({ publish = "*", discoverable = "*", api } = {}) => {
    const url = `${BASE_URL}/admin/v2/listings`
    const params = new URLSearchParams()
    if (publish) {
      params.set("publish", publish)
    }
    if (discoverable) {
      params.set("discoverable", discoverable)
    }
    return await api
      .get(`${url}${params.toString() ? `?${params.toString()}` : ""}`)
      .then((res) => res.data)
  },
  { idGenerator: () => fetchListingsAdminV2.typePrefix },
)

export const createListingAdmin = createAsyncThunk(
  `apps/createListing`,
  async ({ api, url }) => {
    return await api.post(`${BASE_URL}/admin/v2/listings`, {
      url,
    })
  },
  { idGenerator: () => createListingAdmin.typePrefix },
)

export const createBlankAppAdmin = createAsyncThunk(
  `apps/createBlankApp`,
  async ({ api, slug }) => {
    return await api.post(`${BASE_URL}/admin/apps`, {
      slug,
    })
  },
  { idGenerator: () => createBlankAppAdmin.typePrefix },
)

export const updateAppAttributes = createAsyncThunk(
  `apps/attributes`,
  async ({ api, appId, attributes = [] }) => {
    return await api.put(`${BASE_URL}/apps/${appId}/attributes`, {
      attributes,
    })
  },
  { idGenerator: () => updateAppAttributes.typePrefix },
)

export const syncListing = createAsyncThunk(
  `listings/import`,
  async ({ api, listingId }) => {
    return await api.post(`${BASE_URL}/v2/listings/${listingId}/import`)
  },
  { idGenerator: () => syncListing.typePrefix },
)

const isAPendingAction = isPending(
  fetchListingsV2,
  fetchListingsForYouV2,
  fetchListingsNew,
)
const isAFulfilledAction = isFulfilled(
  fetchListingsV2,
  fetchListingsForYouV2,
  fetchListingsNew,
)
const isARejectedAction = isRejected(
  fetchListingsV2,
  fetchListingsForYouV2,
  fetchListingsNew,
)

const listingsAdapter = createEntityAdapter()
const initialState = listingsAdapter.getInitialState({
  forYouIds: [],
  claimedIds: [],
  newIds: [],
  status: {},
  requests: {},
  s: {
    all: [],
    // sort by category
  },
})
export const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    // LEGACY - REMOVE
    setVerificationStatus: (state, action) => {
      // const claimedApp = action.payload
      // listingsAdapter.setOne(state, claimedApp)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllContentV2.fulfilled, (state, action) => {
        const { listing } = action.payload
        if (listing && Object.values(listing).length) {
          listingsAdapter.upsertOne(state, listing)
        }
      })
      .addCase(fetchListingsForYouV2.fulfilled, (state, action) => {
        const list = action.payload
        const forYouIds = map("id")(list)
        state.forYouIds = forYouIds
        listingsAdapter.upsertMany(
          state,
          list.filter((l) => !!l.id),
        )
      })
      .addCase(fetchListingsNew.fulfilled, (state, action) => {
        const { filters } = action.meta?.arg || {}
        const { data: list } = action.payload
        const ids = map("id")(list)
        state.newIds = ids
        // Attach request status as if its another category (see fetchListingsV2)
        state.s.new = { ids, page: 0, filters }
        listingsAdapter.upsertMany(
          state,
          list.filter((l) => !!l.id),
        )
      })
      .addCase(fetchClaimedListingsV2.fulfilled, (state, action) => {
        const list = action.payload
        const claimedIds = map("id")(list)
        state.claimedIds = claimedIds
        listingsAdapter.upsertMany(
          state,
          list.filter((l) => !!l.id),
        )
      })
      .addCase(fetchAllOwnedListsV2.fulfilled, (state, action) => {
        const lists = action.payload
        if (lists?.length) {
          const listings = flatMap(({ listings }) => listings)(lists)
          listingsAdapter.upsertMany(state, listings)
        }
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { listings } = action.payload
        if (listings?.length) {
          listingsAdapter.upsertMany(state, listings)
        }
      })
      .addCase(deleteApp.fulfilled, (state, action) => {
        const { id: listingId } = action.payload

        if (listingId) {
          listingsAdapter.removeOne(state, listingId)
        }
      })
      .addCase(fetchListingsV2.fulfilled, (state, action) => {
        const {
          payload,
          meta: { requestId, arg },
        } = action
        const { data: list, meta } = payload
        const { filters = {} } = arg

        listingsAdapter.upsertMany(
          state,
          list?.filter((l) => !!l.id),
        )
        // Save session seed needed for lazy loading
        if (!state.s.seed) {
          state.s.seed = meta?.session_seed
        }

        const sourceArray = map("id")(list)
        const { ids: targetArray = [], page = 0 } = state.s[requestId] || {}
        const blacklist = "featured"

        // If empty listings array returned by API, set page to -1 to prevent further lazy loading
        const newPage = !!sourceArray?.length ? page + 1 : -1

        if (requestId === blacklist) return

        // If filters/sorts have changed, flush list and start page count over
        if (!isEqual(state.s[requestId]?.filters || {})(filters || {})) {
          state.s[requestId] = { ids: sourceArray, page: 1, filters }
          return
        }

        const newIds = difference(sourceArray, targetArray) || []

        if (targetArray?.length) {
          state.s[requestId] = {
            ids: targetArray.concat(newIds),
            page: newPage,
            filters,
          }
        } else {
          state.s[requestId] = { ids: sourceArray, page: newPage, filters }
        }
      })
      .addCase(fetchListingsAdminV2.fulfilled, (state, action) => {
        const list = action.payload
        listingsAdapter.upsertMany(
          state,
          list.filter((l) => !!l.id),
        )
      })
      .addCase(fetchListingAdminV2.fulfilled, (state, action) => {
        listingsAdapter.upsertOne(state, action.payload)
      })
      .addMatcher(
        isFulfilled(
          fetchListingV2,
          fetchListingAdminV2,
          createListingAdmin,
          updateListing,
        ),
        (state, action) => {
          const item = action.payload
          listingsAdapter.upsertOne(state, item)
        },
      )
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const { requestId } = action.meta
        const status = {
          status: "pending",
          error: null,
          isRefetch: !(state.requests[requestId] === undefined),
        }
        state.requests[requestId] = Object.assign(
          state.requests[requestId] || {},
          status,
        )
      })
      .addMatcher(isAFulfilledAction, (state, action) => {
        const { requestId } = action.meta
        const status = { status: "fulfilled", error: null }
        state.requests[requestId] = Object.assign(
          state.requests[requestId] || {},
          status,
        )
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const { requestId } = action.meta
        const status = { status: "rejected", error: action.error }
        state.requests[requestId] = Object.assign(
          state.requests[requestId] || {},
          status,
        )
      })
  },
})

// exports
export const listingsActions = {
  ...listingsSlice.actions,
  fetchListingsV2,
}
export const listingsReducer = listingsSlice.reducer

// selectors
export const { selectRequestStatus, zipWithRequestStatus } =
  createRequestStatusSelector(listingsSlice.name)

export const {
  selectAll: selectAllListings,
  selectById: selectListingById,
  selectEntities: selectListingEntities,
} = listingsAdapter.getSelectors((state) => state.listings)

/**
 * Selects listings by app IDs.
 * @param {Array} appIds - The app IDs to filter by.
 * @return {Function} A function that takes the state and returns the filtered listings.
 */
export const selectListingsByAppIds =
  (appIds = []) =>
  (state) =>
    selectAllListings(state)
      .filter((item) => appIds.includes(item.app_id))
      .map(formatOneV2)

/**
 * Selects listings by app IDs.
 * @param {Array} appIds - The app IDs to filter by.
 * @return {Function} A function that takes the state and returns the filtered listings.
 */
export const selectListingsByDevUsername = (username) => (state) =>
  selectAllListings(state)
    .filter((item) => username === item.developer_details?.username)
    .map(formatOneV2)

/**
 * Selects listings for the user (version 2).
 * @param {Object} state - The current state object.
 * @return {Array} The listings for the user.
 */
export const selectListingsForYouV2 = (state) => {
  return flow(
    (s) => s.listings.forYouIds,
    zipWithRequestStatus(state, fetchListingsForYouV2.typePrefix),
  )(state)
}

/**
 * Selects new listings (most recent 100).
 * @param {Object} state - The current state object.
 * @return {Object} data - An array of new listings. count: the number of new listings (100).
 */
export const selectListingsNew = (state) => {
  const { ids: data } = state.listings.s?.new || {}
  return {
    count: data?.length,
    data,
  }
}

/**
 * Selects claimed listings (version 2).
 * @param {Object} state - The current state object.
 * @return {Array} The claimed listings.
 */
export const selectClaimedListingsV2 = (state) => {
  return flow(
    (s) => s.listings.claimedIds.map((id) => state.listings.entities[id]),
    formatListV2,
    zipWithRequestStatus(state, fetchClaimedListingsV2.typePrefix),
  )(state)
}

/**
 * Selects a listing by ID (version 2).
 * @param {Function} createCachedSelector - Cached selector creation function.
 * @return {Function} A function that takes the state and listing ID, and returns the selected listing.
 */
export const selectListingByIdV2 = createCachedSelector(
  [(state, id) => state.listings.entities[id]],
  (listing) => formatOneV2(listing),
)((_, listingID) => listingID)

/**
 * Selects the status of listings (version 2).
 * @param {Object} state - The current state object.
 * @param {string} category - The primary category to select.
 * @param {string} subCategory - The sub category to select. (optional)
 * @return {Object} An object containing status booleans for idle, loading, error, success states and the current lazy loading page.
 */
export const selectListingsStatusV2 = createCachedSelector(
  [
    (state) => state.listings.requests,
    (state, category, subCategory) =>
      state.listings.s[getCategoriesString(category, subCategory)]?.page,
    (_, category, subCategory) => getCategoriesString(category, subCategory),
  ],
  (requests, currentPage, categories) => {
    const req = requests[categories] || requests[fetchListingsV2.typePrefix]
    const status = req?.status
    const isRefetch = req?.isRefetch

    // derive status booleans for ease of use
    const isIdle = status === undefined
    const isLoading = status === "pending" || status === undefined
    const isError = status === "rejected"
    const isSuccess = status === "fulfilled"

    return { isIdle, isLoading, isError, isSuccess, isRefetch, currentPage }
  },
)((_, category, subCategory) => `${category}::${subCategory}`)

/**
 * Selects all content (version 2).
 * @param {string} sitePath - The path of the site.
 * @param {string} [overrideType="developer"] - The type of override.
 * @return {Function} A function that takes the state and returns the selected content.
 */
export const selectAllContentV2 =
  (sitePath, overrideType = "developer") =>
  (state) => {
    const listing = formatOneV2(selectListingById(state, sitePath))
    const all = selectAllOverrides(state)
    const override = all?.find(
      (o) => o.app_id === listing?.appId && o?.override_type === overrideType,
    )
    const global = all?.find(
      (o) => o.app_id === listing?.appId && o?.override_type === "global",
    )
    const manifest = selectAllManifests(state)?.find(
      (o) => o.app_id === listing?.appId,
    )

    return flow(
      () => ({ listing, override, manifest, global }),
      zipWithRequestStatus(state, fetchAllContentV2.typePrefix),
    )(state)
  }

export const selectListingsEnum = {
  SELECT_ALL: "all",
  SELECT_PUBLISHED: "published",
  SELECT_DISCOVERABLE: "discoverable",
}

/**
 * Selects listings (version 2).
 * @param {Object} state - The current state object.
 * @param {boolean} [publish=true] - Flag to published apps.
 * @param {boolean} [discoverable=true] - Flag to discoverable apps.
 * @return {Array} The selected listings.
 */
export const selectListingsV2 = (
  state,
  selector = selectListingsEnum.SELECT_DISCOVERABLE,
) => {
  return flow(
    selectAllListings,
    (items) => {
      if (selector === selectListingsEnum.SELECT_ALL) return items
      if (selector === selectListingsEnum.SELECT_PUBLISHED)
        return items.filter((item) => item.publish)
      return items.filter((item) => item.discoverable)
    },
    formatListV2,
    zipWithRequestStatus(state, fetchListingsV2.typePrefix),
  )(state)
}

/**
 * Listings selector for each category page. Featured page returns featured listings grouped by tags
 * @param {Object} state - The current state object.
 * @param {Object} categories - The categories to select `{ primary, subCategory }`.
 * @return {Object} An object containing the count and data (array of listing ids).
 */
export const selectListingsByCategory = createCachedSelector(
  [
    (state, { primary, subCategory }) => {
      if (primary === "featured") {
        return state.listings.ids
      }
      const categories = getCategoriesString(primary, subCategory)
      return state.listings.s[categories]?.ids
    },
    (_, { primary, subCategory }) => getCategoriesString(primary, subCategory),
    (state) => state.listings.entities,
  ],
  (ids, categories, entities) => {
    if (categories === "featured") {
      const withData = ids.map((id) => entities[id])
      return {
        count: ids?.length,
        data: groupByTagsV2((i) => i?.id)(withData),
      }
    }
    return {
      count: ids?.length,
      data: ids,
    }
  },
)((_, { primary, subCategory }) => getCategoriesString(primary, subCategory))

/*
===============================
========= formatters ==========
===============================
*/
export function formatOneV2(listing) {
  if (!listing) return {}

  const { icons = [], links } = listing

  // Default + Maskable Icons
  let defaultIcon, defaultWidth, defaultHeight
  let maskableIcon, maskableWidth, maskableHeight
  if (icons.length) {
    icons.forEach((icon) => {
      const [src, width, height, isMaskable] = [
        icon.src,
        ...icon.sizes.split("x").map(Number),
        icon.purpose?.includes("maskable") || false,
      ]

      if (isMaskable) {
        if (
          (!maskableWidth || width > maskableWidth) &&
          (!maskableHeight || height > maskableHeight)
        ) {
          maskableIcon = src
          maskableWidth = width
          maskableHeight = height
        }
      } else {
        if (
          (!defaultWidth || width > defaultWidth) &&
          (!defaultHeight || height > defaultHeight)
        ) {
          defaultIcon = src
          defaultWidth = width
          defaultHeight = height
        }
      }
    })
  }

  defaultIcon = defaultIcon || icons[0]?.src || missingIcon

  // Website URL
  const url =
    links.find(
      ({ type, platform }) => type === "install" && platform === "web_app",
    )?.src ||
    links.find(
      ({ type, platform }) => type === "install" && platform === "website",
    )?.src ||
    links.find(
      ({ platform }) => platform === "web_app" || platform === "website",
    )?.src

  const isPWA = isPwa(listing?.platforms)
  const isApproved = isAppApproved(listing?.tags)
  const allLaunchLinks = generateAllLaunchLinks(links, isPWA)
  return mapKeys(camelCase)({
    ...listing,
    url,
    isPWA,
    isApproved,
    allLaunchLinks,
    developerDetails: mapKeys(camelCase)(listing.developer_details),
    iconDefault: defaultIcon,
    iconMaskable: maskableIcon,
  })
}

export function formatListV2(list = []) {
  return list.map(formatOneV2)
}

/*
===============================
=========== utility ===========
===============================
*/

function groupByTagsV2(fn = (item) => item.id) {
  return (apps) =>
    apps.reduce(
      (acc, item) => {
        const { tags = [] } = item

        // group ids by tag
        const all = uniq(tags)
        all.forEach((tag) => {
          if (!acc[tag]) {
            acc[tag] = []
          }

          // JS hack to ensure arrays are even length
          // Since js arrays are objects, store the first item under key "next"
          // Every successive iteration is either saving the current item under "next", or attaching both the stored "next" value and the current item, ensuring an even length array
          if (acc[tag].next) {
            acc[tag] = [...acc[tag], acc[tag].next, fn(item)]
            delete acc[tag].next
          } else {
            acc[tag].next = fn(item)
          }
        })

        // attach id to acc
        acc.ids.push(fn(item))

        return acc
      },
      { ids: [] },
    )
}

function appendFilterParams({
  params,
  subCategory,
  sortBy,
  rating,
  onlyApproved,
  appType,
  deviceTypes,
  installable,
  platformTypes,
}) {
  if (subCategory) params.append("category", subCategory)
  if (sortBy) params.append("sort_by", sortBy)
  if (rating) params.append("min_rating", mapRatings[rating])
  if (onlyApproved) params.append("only_approved", onlyApproved)
  if (appType) params.append("app_type", appType)
  if (deviceTypes) params.append("device_types", deviceTypes.join(","))
  if (installable) params.append("platform", PLATFORM.INSTALLABLE)
  forEach(platformTypes, (platform) => params.append("platform", platform))
}

const mapRatings = {
  "4_5_star": 4.5,
  "4_star": 4,
  "3_5_star": 3.5,
  "3_star": 3,
}
