import { first } from "lodash/fp"
import missingIcon from "../shared/assets/missing-icon.png"
import { PLATFORM } from "../features/home/constants/platforms"

export const createRequestStatusSelector = (resource) => {
  const selectRequestStatus = (state, requestId = "all") => {
    const reqInfo = state[resource].requests[requestId]
    const error = reqInfo?.error
    const isLoading = reqInfo?.status === "pending"
    const isIdle = !reqInfo
    return { isLoading, isIdle, error, status: reqInfo?.status || "idle" }
  }

  const zipWithRequestStatus =
    (state, requestId = "all") =>
    (data) => ({
      data,
      ...selectRequestStatus(state, requestId),
    })

  return { selectRequestStatus, zipWithRequestStatus }
}

// Icon Utils
const splitSize = (icon) => icon.sizes?.split("x").map(Number)

export const isValidAspectRatio = (width, height) =>
  Math.abs(width / height - 1) < 0.1

export const isValidIconSize = (width, height) => width >= 250 && height >= 250

export const isValidIcon = (icon) => {
  if (!icon || !icon.sizes || !icon.sizes.includes("x")) return false
  const [width, height] = splitSize(icon)
  return isValidAspectRatio(width, height) && isValidIconSize(width, height)
}

const isCurrentLarger = (prev, curr) => {
  if (!prev) return true
  const [pWidth, pHeight] = splitSize(prev)
  const [cWidth, cHeight] = splitSize(curr)
  return cWidth > pWidth && cHeight > pHeight
}

export const createGetDefaultIcon = (purpose) => (icons) => {
  const predicateFn = {
    default: (p) => p !== "maskable",
    maskable: (p) => p === "maskable",
  }[purpose]

  return icons?.reduce((prev, curr) => {
    if (
      isValidIcon(curr) &&
      predicateFn(curr.purpose) &&
      isCurrentLarger(prev, curr)
    ) {
      return curr
    }
    return prev
  }, null)
}

export const getDefaultIcon = createGetDefaultIcon("default")
export const getDefaultMaskableIcon = createGetDefaultIcon("maskable")

export const getFallbackIcon = (icons) => {
  const defaultIcon = getDefaultIcon(icons)
  const fallbackIcon = !!icons?.length ? icons[0] : { src: missingIcon }
  return defaultIcon || fallbackIcon
}

export const getMaskableFallbackIcon = (icons) => {
  const fallbackIcon = !!icons?.length ? icons[0] : { src: missingIcon }
  return getDefaultMaskableIcon(icons) || getDefaultIcon(icons) || fallbackIcon
}

// TODO: rm false and uncomment line 75 when we want to show approved badge
export const isAppApproved = () => false
// app?.tags?.includes("store_approved")

export const isPwa = (platforms) =>
  platforms?.some((platform) => platform === PLATFORM.INSTALLABLE)

export const parseAuditDetails = (src) => {
  if (!src) return {}
  const [appId, end] = src?.split("/")?.slice(-2)
  const unixTimestamp = first(end?.split("."))
  return { appId, unixTimestamp }
}

export const buildUrl = (uri, args, allowed) => {
  const url = new URL(uri)
  const params = new URLSearchParams()

  allowed.forEach((param) => {
    if (args[param] !== undefined) params.append(param, args[param])
  })

  url.search = params.toString()
  return url.toString()
}
