import { Form, Formik } from "formik"
import * as Yup from "yup"
import { BASE_URL, useAuthApi } from "../../../../api/api"
import { Button, buttonTrackers } from "../../../../shared/components/ui/Button"
import { useDispatch } from "react-redux"
import {
  failureNotification,
  successNotification,
} from "../../../../shared/redux/middleware/notifications.middleware"

export const CrawlSubmissionForm = () => {
  const api = useAuthApi()
  const dispatch = useDispatch()
  return (
    <div>
      <Formik
        initialValues={{
          url: "",
          web_app: false,
        }}
        validationSchema={Yup.object({
          url: Yup.string().url("Invalid URL").required("Required"),
          web_app: Yup.bool().required("Required"),
        })}
        onSubmit={async ({ url, web_app = false }, config) => {
          try {
            await api.post(`${BASE_URL}/v2/crawl/site`, {
              domains: [{ domain: url, webApp: web_app }],
            })
            dispatch(successNotification("Crawl request submitted"))
            config.resetForm({ values: { url: "", web_app: false } })
          } catch (error) {
            config.setStatus({ msg: "Error submitting crawl", error: true })
            dispatch(failureNotification("Error submitting crawl", error))
          }
        }}
      >
        {({ values, setFieldValue, status, dirty }) => (
          <Form className="flex flex-row items-center gap-x-20 rounded-md border-gray-400 p-2 w-fit">
            <div>
              <p className="text-gray-700 mb-2">Request a new app crawl</p>
              <input
                type="url"
                placeholder="https://..."
                className="rounded-md w-80"
                value={values.url}
                onChange={(event) => setFieldValue("url", event.target.value)}
              />
              <div className="flex items-center gap-x-1 mt-1">
                <input
                  id="web_app"
                  type="checkbox"
                  className="rounded-md"
                  value={values.web_app}
                  onChange={(event) =>
                    setFieldValue("web_app", event.target.checked)
                  }
                />
                <label htmlFor="web_app" className="text-sm text-gray-700">
                  Web App
                </label>
              </div>
            </div>
            <Button
              type="submit"
              disabled={!dirty}
              tracker={buttonTrackers.NONE}
              className="disabled:opacity-70"
            >
              Submit
            </Button>
            {status?.message && <p>{status.message}</p>}
          </Form>
        )}
      </Formik>
    </div>
  )
}
