import React, { Suspense } from "react"
import NoMatch from "./shared/components/layout/NoMatch"
import App from "./App"
import { Navigate } from "react-router-dom"
import { AppTable } from "./features/developer/routes/AppTable/AppTable"
import Home from "./features/home/NewHome"

// Admin Dashboard
const AdminAddApp = React.lazy(() =>
  import("./features/developer/components/AdminAddApp"),
)
const AdminAdCampaignReview = React.lazy(() =>
  import("./features/developer/routes/AdminAdCampaignReview"),
)
const AdminAppReview = React.lazy(() =>
  import("./features/developer/routes/AdminAppReview"),
)
const AdminApp = React.lazy(() =>
  import("./features/developer/routes/AdminApp"),
)
const AggregateReviews = React.lazy(() =>
  import("./features/developer/routes/AggregateReviews"),
)

// Others
const AdImagePreviewPage = React.lazy(() =>
  import("./features/ad_preview/AdImagePreviewPage"),
)

export const routes = [
  {
    path: "index.html",
    element: <Navigate replace to="/" />,
  },
  {
    path: "index.php",
    element: <Navigate replace to="/" />,
  },
  {
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/developer/admin",
        children: [
          {
            path: "add-app",
            element: withFallback(AdminAddApp),
          },
          {
            path: "app-management",
            element: withFallback(AppTable),
          },

          {
            path: "ad-campaign-review",
            element: withFallback(AdminAdCampaignReview),
          },

          {
            path: "app-review",
            element: withFallback(AdminAppReview),
          },
          {
            path: ":sitePath",
            element: withFallback(AdminApp),
          },
          {
            path: "aggregate-reviews",
            element: withFallback(AggregateReviews),
          },
        ],
      },
    ],
  },
  {
    path: "ad-image-preview",
    element: withFallback(AdImagePreviewPage),
  },

  {
    path: "*",
    element: <NoMatch />,
  },
]

// util
function withFallback(Component, Fallback = FallbackLoader) {
  return (
    <Suspense fallback={<Fallback />}>
      <Component />
    </Suspense>
  )
}

function FallbackLoader() {
  const classes = {
    dot: "w-4 h-4 my-12 mx-1 bg-gray-400 rounded-full",
  }
  return (
    <div className="flex justify-center pt-24">
      <div className={`${classes.dot} animate-loader`} />
      <div className={`${classes.dot} animation-delay-200 animate-loader`} />
      <div className={`${classes.dot} animation-delay-400 animate-loader`} />
    </div>
  )
}
