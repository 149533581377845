import { overSome, range } from "lodash/fp"
import { v4 as uuidv4 } from "uuid"
import deviceTypes from "../../api/preferences/deviceTypes"
import IconBuilder from "../assets/IconBuilder"
import { SubtractedCircle } from "../assets/CustomIcons"
import { categories } from "../../features/home/constants/categories"
import * as Yup from "yup"
import { twMerge } from "tailwind-merge"
import { capitalize } from "lodash"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

// https://www.npmjs.com/package/clsx (docs)
export function clsx(...args) {
  const result = []

  for (const arg of args) {
    if (!arg) continue

    const type = typeof arg

    if (type === "string" || type === "number") {
      result.push(arg)
    } else if (Array.isArray(arg) && arg.length) {
      const inner = clsx(...arg)
      if (inner) {
        result.push(inner)
      }
    } else if (type === "object") {
      for (const key in arg) {
        if (arg[key]) {
          result.push(key)
        }
      }
    }
  }

  return result.join(" ")
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ")
}

/* eslint-disable no-useless-escape */
export const parseUrl = (url) => {
  var m = url.match(
      /^((?:([^:\/?#]+:)(?:\/\/))?((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?))?([^?#]*)(\?[^#]*)?(#.*)?$/,
    ),
    r = {
      hash: m[10] || "", // #asd
      host: m[3] || "", // localhost:257
      hostname: m[6] || "", // localhost
      href: m[0] || "", // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: m[1] || "", // http://username:password@localhost:257
      pathname: m[8] || (m[1] ? "/" : ""), // /deploy/
      port: m[7] || "", // 257
      protocol: m[2] || "", // http:
      search: m[9] || "", // ?asd=asd
      username: m[4] || "", // username
      password: m[5] || "", // password
    }
  if (r.protocol.length === 2) {
    r.protocol = "file:///" + r.protocol.toUpperCase()
    r.origin = r.protocol + "//" + r.host
  }
  r.href = r.origin + r.pathname + r.search + r.hash
  return r
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand("copy", true, text)
  }
}

export function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

export const isAppleAppStoreUrl = (string) => {
  return /^https:\/\/apps\.apple\.com\/us\/app\/[\w-]+\/id\d+$/.test(string)
}

export const isPlayStoreUrl = (string) => {
  return /https:\/\/play\.google\.com\/store\/apps\/details\?id=[\w\.]+/.test(
    string,
  )
}

export const isWindowsStoreUrl = (string) =>
  /^https?:\/\/apps\.microsoft\.com\/store\/detail\/.*/.test(string)

export const isAppStoreLink = overSome([
  isAppleAppStoreUrl,
  isPlayStoreUrl,
  isWindowsStoreUrl,
])

export const getDomainWithoutSubdomain = (url) => {
  if (!url) return ""
  const urlParts = new URL(url).hostname.split(".")

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".")
}

export const validateSameDomain = (testUrl, domainUrl) => {
  const testUrlDomain = getDomainWithoutSubdomain(testUrl)
  const domainUrlDomain = getDomainWithoutSubdomain(domainUrl)

  return testUrlDomain === domainUrlDomain
}

export const validateDownloadableUrl = async (url) => {
  return true
  // TODO: not sure if necessary atm
  // const response = await fetch(url, { method: "HEAD" })
  // const contentType = response.headers.get("content-type")

  // // QUESTION: not sure if octet-stream check is needed
  // if (
  //   (contentType && contentType.startsWith("application/")) ||
  //   contentType === "application/octet-stream"
  // ) {
  //   return true
  // }

  // return false
}

var rank = {
  TOO_SHORT: (score) => ({
    score,
    text: "Too short",
    color: "bg-gradient-to-r from-red-600 to-red-500",
    emoji: "🥴",
  }),
  WEAK: (score) => ({
    score,
    text: "A bit weak",
    color: "bg-gradient-to-r from-yellow-600 to-yellow-500",
    emoji: "🤨",
  }),
  MEDIUM: (score) => ({
    score,
    text: "Could be better",
    color: "bg-gradient-to-r from-yellow-500 to-yellow-400",
    emoji: "😮",
  }),
  STRONG: (score) => ({
    score,
    text: "Strong password",
    color: "bg-gradient-to-r from-green-700 to-green-500",
    emoji: "😲",
  }),
  VERY_STRONG: (score) => ({
    score,
    text: "Wunderkind",
    color: "bg-gradient-to-r from-green-600 to-green-400",
    emoji: "🤓",
  }),
}

export const rankPassword = (password) => {
  var upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[^A-Za-z0-9]/,
    minLength = 6,
    score = 0

  // Increment the score for each of these conditions
  if (upper.test(password)) score++
  if (lower.test(password)) score++
  if (number.test(password)) score++
  if (special.test(password)) score++

  if (password.length > minLength) {
    // Increment the score for each additional character (weighted by 1.25)
    score += Math.floor((password.length - minLength) / 1.25)
  }

  // Return a ranking based on the calculated score
  if (password.length < minLength) return rank.TOO_SHORT(score)
  if (score < 3) return rank.WEAK(score)
  if (score < 4) return rank.MEDIUM(score)
  if (score < 5) return rank.STRONG(score)
  return rank.VERY_STRONG(score)
}

export const getStarWidth = (rating, avg_rating) => {
  if (Math.floor(avg_rating) !== rating) return 100
  const w = (avg_rating % 1).toFixed(2) * 100
  if (w === 0 && w <= 10) return 0
  if (w > 10 && w <= 25) return 25
  return w
}

export const handleBrokenImage = (event) => {
  event.target.src = `${process.env.PUBLIC_URL}/maskable1024.png`
}

export const isAlphanumeric = (str) =>
  /^[a-zA-Z0-9]+([ ]{1}[a-zA-Z0-9]+)*$/.test(str)

export const loadingIds = (n) => range(0)(n).map(() => uuidv4())

export const isiOS = () =>
  // navigator.platform is marked as deprecated but still currently supported on all browsers (11/7/23)
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform) || /iPad|iPhone|iPod/.test(navigator.userAgent)

export const getDeviceTypeIcons = (links) => {
  const ecosystemIcons = []
  // if no install links, assume web app
  if (!links) {
    ecosystemIcons.push(deviceTypes["web_app"].icon)
  } else {
    if (links?.find((link) => link.type === "web_app")) {
      ecosystemIcons.push(deviceTypes["web_app"].icon)
    }
    if (links?.find((link) => link.type === "ios")) {
      ecosystemIcons.push(deviceTypes["ios"].icon)
    }
    if (links?.find((link) => link.type === "android")) {
      ecosystemIcons.push(deviceTypes["android"].icon)
    }
  }
  return ecosystemIcons
}

export const DeviceTypeDots = ({ icons }) => {
  if (!icons.length) return null
  const lastIcon = icons.pop()
  return (
    <div className="hidden rounded-full">
      {icons.length === Object.keys(deviceTypes).length ? (
        // This was in the designs but I'm not sure I like it
        <div className="rounded-full bg-neutral-50 px-2 py-1 text-xs">
          <p>ALL OS</p>
        </div>
      ) : (
        <div className="relative inline-flex">
          {icons?.map((icon, index) => (
            <div key={index} className="relative h-6 w-6">
              <IconBuilder
                icon={icon}
                classes="absolute w-4 my-[6px] ml-1 h-4 text-gray-700"
              />
              <SubtractedCircle
                key={index}
                className=" h-6 w-6 text-neutral-50"
              />
            </div>
          ))}
          <div
            className={classNames(
              "rounded-full bg-neutral-50 p-1",
              // `left-[${index * 10}]px]`,
            )}
          >
            <IconBuilder icon={lastIcon} classes="w-4 h-4 text-gray-700" />
          </div>
        </div>
      )}
    </div>
  )
}

export const getOperatingSystem = () => {
  if (isiOS()) {
    return "iOS"
  }
  const matchedPlatform = ["Win", "Mac", "Linux", "iOS", "Android"].find(
    (platform) => navigator.userAgent.includes(platform),
  )
  if (!!matchedPlatform) {
    return matchedPlatform
  }
  return "Unknown"
}

export const generateAllLaunchLinks = (links = [], pwa = false) => {
  const launchLinks = links
    .filter((item) => !!item && item?.type !== "social")
    .map((item) => {
      let type = item?.type
      let platform = item?.platform

      if (platform === "web_app" || platform === "website") {
        if (pwa) {
          return { url: item?.src, type: "pwa" }
        }
        return { url: item?.src, type: "web_app" }
      } else if (type === "install") {
        return { url: item?.src, type: `${platform}_store` }
      } else if (type === "direct_install") {
        return { url: item?.src, type: `${platform}_direct` }
      }
      return null
    })
    //TODO: feature flagging app store & direct install links
    .filter(
      (item) =>
        !item?.type?.includes("store") && !item?.type?.includes("direct"),
    )

  return launchLinks
}

export const getNativeLaunchLink = (allLaunchLinks = []) => {
  const operatingSystem = getOperatingSystem()
  return allLaunchLinks.find((link) => {
    return {
      ios: operatingSystem === "iOS",
      android: operatingSystem === "Android",
      macos: operatingSystem === "Mac",
      windows: operatingSystem === "Win",
      linux: operatingSystem === "Linux",
      web_app: false,
    }[link.type.replace("_store", "").replace("_direct", "")]
  })
}

export const getWebLaunchLink = (allLaunchLinks) =>
  allLaunchLinks.find((link) => link.type === "web_app" || link.type === "pwa")

export const pluralize = (count, noun, suffix = "s", verb = null) => {
  let nounWithSuffix = count !== 1 ? noun + suffix : noun
  let verbStr = ""

  if (verb) {
    verbStr = count !== 1 ? ` ${verb.plural}` : ` ${verb.singular}`
  }

  return `${count} ${nounWithSuffix}${verbStr}`
}

/**
 * Variant of pluralize() allowing config object instead of ordered params
 * Can pluralize the noun without prefixing with the count
 */
export const plural = (count, noun, { suffix = "s", showCount = true } = {}) =>
  `${showCount ? count : ""} ${noun}${count !== 1 ? suffix : ""}`.trimStart()

export const getCategoryName = (categoryID) =>
  categories.find((category) => category.id === categoryID)?.name

export const getSubCategoryName = (categoryID, subCategoryID) =>
  categories
    .find((category) => category.id === categoryID)
    ?.subCategories?.find((subCategory) => subCategory.id === subCategoryID)
    ?.name

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export const appendSearchParams = (url = "", searchParams) => {
  if (searchParams.toString()) {
    return `${url}?${searchParams.toString()}`
  } else {
    return url
  }
}

export const validateEmail = (value) => {
  try {
    Yup.string()
      .required("Email is required.")
      .email("Invalid email format.")
      .validateSync(value)
  } catch (error) {
    return false
  }
  return true
}

export const getIOSVersion = () => {
  var ua = navigator.userAgent
  var uaindex

  uaindex = ua.indexOf("OS ")

  // determine version
  if (uaindex > -1) {
    return ua.slice(uaindex + 3, uaindex + 7).replace("_", ".")
  }
  return null
}

export const nameInitials = (displayName) => {
  if (!displayName) return ""
  const nameSegments = displayName?.split(" ")
  return nameSegments.length > 1
    ? `${capitalize(nameSegments[0].charAt(0))}${capitalize(
        nameSegments[1].charAt(0),
      )}`
    : `${nameSegments[0].charAt(0)}${nameSegments[0].charAt(1) || ""}`
}

export const concatenateIfExists = (separator = " ", ...strings) => {
  return strings.filter(Boolean).join(separator)
}

/**
 * Assemble dot notation categories string
 * @param {string} primary category id
 * @param {string} subCategory category id
 * @returns {string} dot notation string ex: "games.multiplayer"
 */
export const getCategoriesString = (primary, subCategory) => {
  return concatenateIfExists(".", primary, subCategory)
}

/**
 * Build cloudflare image URL
 * DOES NOT MODIFY IMAGE SRC IN DEVELOPMENT
 * @param {string} url image src (eg aws s3)
 * @param {integer} width image width
 * @returns {string} optimized cloudflare url to image
 */
export const cfImgSrc = (
  src,
  width,
  { quality = 85, format = "auto" } = {},
) => {
  if (!src) return undefined

  if (process.env.NODE_ENV === "development") {
    if (!width) {
      console.warn("[optimizeImg] missing required arg `width`")
    }

    // skip optimization for `localhost`
    if (src.startsWith("http://localhost")) {
      return src
    }

    // disallow relative urls
    if (!src.startsWith("https://")) {
      console.warn("[optimizeImg] image url must be absolute")
    }
  }

  return `https://static.store.app/cdn-cgi/image/width=${width},quality=${quality},format=${format}/${src}`
}

export function hasRole(claims, assessedRole) {
  if (!claims) return false

  switch (assessedRole) {
    case "developer":
      return claims.role === "developer" || claims.role === "admin"
    case "admin":
      return claims.role === "admin"
    default:
      return true
  }
}
